div.homepage-Form-tabs a {
  text-decoration: none;
}
.activeHomeLabel {
  color: #0485f4;
  background: #eff8fd;
}
.is-error {
  color: red;
}

input::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
  height: 100%;
  right: 0;
  position: absolute;
  top: 0;
  width: 30%;
  padding-left: 2rem;
}

.Input2-pokyny,
.Input2-helper {
  top: calc(50% - 5px);
  right: 24px;
}

.Input2-input.is-invalid {
  border-color: #ff4a77;
}

.Input2-input.is-invalid:focus {
  border-color: #ff4a77;
  box-shadow: 0 0 0 5px rgba(255, 74, 119, 0.2);
}

.Confirmation-icon {
  display: block;
  width: 3.846153846153846em;
  height: 3.846153846153846em;
  background-color: #5edb3d;
  border-radius: 50%;
  position: relative;
  margin: 0 auto 1.269230769230769em;
}

.Confirmation .VisualIcon {
  width: 7rem;
  height: 70px;
  height: 7rem;
  margin: 0 auto 1.269230769230769em;
}

.Confirmation-title:after {
  display: block;
  content: "";
  width: 40px;
  height: 2px;
  background-color: #0485f4;
  margin: 0.5em auto;
}

/* .homepage-WhyCard-image:before { 
  padding-bottom: 60%;
}  */

.Input2-input:disabled,
input:disabled {
  background-color: #ecedf0;
  color: #b2b7c4;
  border-color: #cfd2da;
}

.inputDateWidth {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.capitalize {
  text-transform: capitalize;
}

.Modal-inner p {
  color: #707990;
  line-height: 1.875;
  font-size: 16px;
}

/*Louder*/
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Loader--fullscreen {
  position: fixed;
  top: 100%;
  left: 100%;
  right: 0;
  bottom: 0;
  visibility: hidden;
  z-index: -1;
  color: #fff;
  flex-flow: column;
  transition: 0s visibility 0.5s, 0s top 0.5s, 0s right 0.5s, 0s left 0.5s,
    0s bottom 0.5s, 0s z-index 0.5s;
}

.Loader--fullscreen:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #01215a;
  opacity: 0;
  transition: 0.3s opacity 0.2s;
}

.Loader-icon {
  padding: 20px;
  background: #fff;
  border-radius: 50%;
  position: relative;
  margin-bottom: 10px;
  opacity: 0;
  transition: 0.3s opacity 0.2s;
}

.Loader-text {
  position: relative;
  opacity: 0;
  transition: 0.3s opacity 0.1s;
}

.Loader.is-active {
  z-index: 999;
  visibility: visible;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.3s visibility, 0s top, 0s right, 0s left, 0s left, 0.3s z-index;
}

.Loader.is-active:before {
  opacity: 0.8;
  transition: 0.3s opacity;
}

.Loader.is-active .Loader-text {
  opacity: 1;
  transition: 0.3s opacity 0.2s;
}

.Loader.is-active .Loader-icon {
  opacity: 1;
  transition: 0.3s opacity 0.1s;
}

.TripleCircle {
  position: relative;
  width: 6em;
  height: 6em;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #f1c40f;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.TripleCircle:before,
.TripleCircle:after {
  content: "";
  position: absolute;
  border: 3px solid transparent;
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.TripleCircle:before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-top-color: #3498db;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

.TripleCircle:after {
  top: 12px;
  left: 12px;
  right: 12px;
  bottom: 12px;
  border-top-color: #2ecc71;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}

.Loader--fullscreen .TripleCircle {
  width: 120px;
  width: 12rem;
  height: 120px;
  height: 12rem;
}

.Loader--fullscreen .Loader-icon {
  padding: 50px;
}

.Form-headline {
  font-size: 20px;
  font-size: 2rem;
  color: #01215a;
  text-align: center;
  margin-bottom: 15px;
}

.Info {
  font-size: 1.7rem;
  border-radius: 0.25rem;
}

.Info--red {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.Info--green {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.pt-15 {
  padding-top: 15px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}

.Icon:before {
  padding-right: 15px;
}

.Icon:before {
  color: #707990;
  font-size: 1.8rem;
}

.Icon.Icon--crossTiny:before {
  color: #fff;
  font-size: inherit;
  padding-right: 0px;
}

.m-15 {
  margin: 15px;
}

@media screen and (max-width: 33.75em) {
  .exekuce-Overview {
    flex-direction: column;
  }
}

/* .TableDataPair{
  flex: 1 1 auto;
  min-width: 0;
} */

.homepage-Form button.buttonH60 {
  height: 60px;
}

@media screen and (min-width: 75em) {
  .homepage-PageHeader {
    padding: 50px 0 50px;
  }
}

.Input2-label-sub {
  font-weight: 200;
  font-size: 90%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.isNonAuthenticated {
  font-size: 20px;
  color: #707990;
}

.isAuthenticated {
  font-size: 20px;
  color: #0485f4;
}

.FormItem .Label {
  display: flex;
  align-items: center;
}

.Icon--circleQuestionmark,
.cursor-pointer {
  cursor: pointer;
}

.homepage-Form-pokynyOpener {
  padding-top: 0;
  padding-bottom: 0;
}

.ConditionalShow-trigger {
  align-items: center;
}

.exekuce-OrderStep-item {
  pointer-events: none;
}

.graySmaller {
  color: #707990;
  font-size: 1.4rem;
}

.detailPrice .Price {
  padding-right: 30px;
}

.show-mobile {
  display: none;
}

@media screen and (max-width: 33.75em) {
  .detailPrice {
    flex-direction: column;
  }
  .detailPrice .Price {
    padding-right: 0;
  }
}

@media screen and (max-width: 47.9375em) {
  .show-mobile {
    display: inherit;
  }
}

.mw-auto {
  min-width: auto;
}

.smaller {
  font-size: 2rem;
}

.text-left {
  text-align: left;
}

.just-flex-start {
  justify-content: flex-start;
}

.Form-subtitle.smaller {
  font-size: 1.7rem;
}

.align-self-center {
  align-self: center;
}

.ml-auto {
  margin-left: auto;
}

.exekuce-InfoCount.justifyCenter {
  justify-content: center;
}

.noBorder {
  border: none;
}

.VisualIcon.smaller {
  width: 50px;
  width: 5rem;
  height: 50px;
  height: 5rem;
}

.Flex-column {
  flex-direction: column;
  display: flex;
}

.pr-15 {
  padding-right: 15px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #000;
  filter: alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.display-none {
  display: none;
}

.lightbox {
  text-align: center;
  line-height: 0;
  position: absolute;
  left: 0;
}

.lightbox {
  width: 100%;
  z-index: 10000;
  font-weight: 400;
  outline: 0;
}

.lb-outerContainer {
  position: relative;
  /* width: 250px;
  height: 250px; */
  margin: 0 auto;
  border-radius: 4px;
  background-color: #fff;
}

.lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  border: 4px solid #fff;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

element.style {
  width: 1258px;
}
.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

table.dispatchingTable {
  border-spacing: 0;
  border: 1px solid black;
  margin: 20px;
}

table.dispatchingTable tr:last-child td {
  border-bottom: 0;
}

table.dispatchingTable th,
table.dispatchingTable td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

table.dispatchingTable td:last-child,
table.dispatchingTable th:last-child {
  border-right: 0;
}

input[type="date"] {
  -webkit-appearance: none;
}

.font-small {
  font-size: 1.5rem;
}
